<template>
  <v-dialog v-model="show" max-width="12000px">
    <v-card>
      <v-card-title class="headline grey lighten-2">Set up things</v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="8">
                <v-text-field v-model="docUrl" label="Document URL" outlined dense required></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-flex x1 class="pt-3">
                  <v-btn @click="fetchDocFromStore" color="primary">load</v-btn>
                  <v-btn @click="refreshDataFromDoc" color="primary">refresh</v-btn>
                </v-flex>
              </v-col>
            </v-row>
            <v-row v-if="showLanguageForm">
              <v-col cols="6">
                <v-select
                  :hint="`${primaryHint}...`"
                  persistent-hint
                  :items="getAvailableLanguages"
                  v-model="defaultPrimary"
                  v-on:change="updateHints"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-select
                  :hint="`${secondaryHint}...`"
                  persistent-hint
                  :items="getAvailableLanguages"
                  v-model="defaultSecondary"
                  v-on:change="updateHints"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          :disabled="!allowSave"
          v-if="showLanguageForm"
          color="success"
          @click="setUpState"
        >Save!</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { extractIdFromUrl } from "../utils/utils";

export default {
  data: () => ({
    valid: false,
    primaryLanguage: "",
    secondaryLanguage: "",
    docUrl: "",
    showLanguageForm: false,
    allowSave: false
  }),
  props: {
    value: Boolean
  },
  created() {
    // TODO: setupCompleteGetter?
    if (this.getDocId && this.getMeta) {
      this.showLanguageForm = true;
      this.docId = this.getDocId;
      const meta = this.getMeta;
      this.defaultPrimary = meta.primaryLanguage;
      this.defaultSecondary = meta.secondaryLanguage;
    }
    // TODO: this doesnt work in first
    this.primaryHint = this.getThreeWordsByColumnName(this.defaultPrimary);
    this.secondaryHint = this.getThreeWordsByColumnName(this.defaultSecondary);
  },

  methods: {
    ...mapActions(["fetchDoc", "setUpWords", "refreshWordList"]),
    updateHints() {
      this.primaryHint = this.getThreeWordsByColumnName(this.defaultPrimary);
      this.secondaryHint = this.getThreeWordsByColumnName(
        this.defaultSecondary
      );
    },
    refreshDataFromDoc() {
      /**
       * TODO:
       * - update UI to inform about new words
       * - eventually, do it in background (as well) without users' attention
       */
      const docId = extractIdFromUrl(this.docUrl);
      if (docId == this.getDocId) {
        this.refreshWordList();
      } else {
        console.warn("You can refresh only same doc!");
      }
    },
    async fetchDocFromStore() {
      // TODO: name is terrible
      const docId = extractIdFromUrl(this.docUrl);
      await this.fetchDoc(docId);
      const columnNames = this.getAvailableLanguages;
      this.primaryLanguage = columnNames[0];
      this.secondaryLanguage = columnNames[1];
      this.showLanguageForm = true;
      this.allowSave = true;
    },
    setUpState() {
      this.setUpWords([this.primaryLanguage, this.secondaryLanguage]);
      this.$emit("set-up", true);
      this.show = false;
    }
  },
  computed: {
    ...mapGetters([
      "getAvailableLanguages",
      "getDocId",
      "setupCompleteGetter",
      "getMeta",
      "getThreeWordsByColumnName"
    ]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    defaultPrimary: {
      get() {
        return this.primaryLanguage;
      },
      set(value) {
        this.primaryLanguage = value;
      }
    },
    defaultSecondary: {
      get() {
        return this.secondaryLanguage;
      },
      set(value) {
        this.secondaryLanguage = value;
      }
    }
  }
};
</script>