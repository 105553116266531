<template>
  <v-alert border="left" colored-border color="blue" elevation="2">
    Your deck has {{sessionStats.cardsCount}} cards
    <br />
    You saw {{sessionStats.cardsSeen}} cards, including {{sessionStats.repeatsCount}} repeats
    <br />

    <v-list-item two-line v-for="repeat in sessionStats.repeats" :key="repeat.card.primary.text">
      <v-list-item-content>
        <v-list-item-title>{{repeat.card.primary.text}}</v-list-item-title>
        <v-list-item-subtitle>repeated {{repeat.count}} times</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-btn
      @click="$emit('button-callback')"
    >Restart training session</v-btn>
  </v-alert>
</template>

<script>
// TODO: consider dialog instead
// see: https://vuetifyjs.com/en/components/dialogs/#usage
export default {
  props: ["sessionStats"]
};
</script>