<template>
  <div class="pa-2">
    <v-card class="ma-2 pa-2" elevation="0" v-if="showCard">
      <v-card-text>{{ `${trainingSession.sessionState[0]}/${trainingSession.sessionState[1]} + ${trainingSession.sessionState[2]} repeats`}}</v-card-text>
      <v-progress-linear :value="sessionProgress"></v-progress-linear>

      <v-card-title class="display-2">{{ trainingSession.currentCard.primary }}</v-card-title>
      <v-card-text class="display-1">{{ tip }}</v-card-text>
      <v-card-actions>
        <v-container>
          <v-row v-if="!showRatingButtons">
            <v-col cols="12">
              <ButtonWithInfotip
                v-on:button-callback="showTranslation"
                buttonText="Show translation"
                infoTipText="Show translation"
              />
            </v-col>
          </v-row>
          <v-row v-if="showRatingButtons">
            <v-col cols="12">
              <ButtonWithInfotip
                v-on:button-callback="nextWord('good')"
                buttonText="Correct"
                infoTipText="Correct, next word"
                iconName="mdi-check-circle-outline"
                btnColor="success"
              />
              <ButtonWithInfotip
                v-on:button-callback="nextWord('soso')"
                buttonText="50/50"
                infoTipText="50/50, next word"
                iconName="mdi-circle-half-full"
                btnColor="info"
              />
              <ButtonWithInfotip
                v-on:button-callback="nextWord('bad')"
                buttonText="Incorrect"
                infoTipText="Schedule repeat, next word"
                iconName="mdi-cancel"
                btnColor="error"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <SessionStats
      v-if="showStats"
      v-bind:sessionStats="lastSessionStats"
      v-on:button-callback="showNewSessionModalAction"
    />
    <NewSessionModal
      v-if="showNewSessionModal"
      v-model="showNewSessionModal"
      @newSessionWordIds="createNewSession"
    />
  </div>
</template>

<script>
import { shuffle } from "../utils/utils.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import ButtonWithInfotip from "../components/ButtonWithInfotip";
import SessionStats from "../components/SessionStats";
import { NewTrainingSession, AnswerTypeEnum } from "../models/trainingSession";
import NewSessionModal from "../components/NewSessionModal";
import { calculateNextRepeatDate } from "../utils/aglos";
import moment from "moment";


export default {
  components: {
    ButtonWithInfotip,
    SessionStats,
    NewSessionModal
  },
  data() {
    return {
      tip: "_", // TODO: name
      trainingSession: undefined,
      lastSessionStats: undefined,
      showRatingButtons: false,
      currentCard: undefined,
      // UI components
      showStats: false,
      showCard: false, // TODO: name
      showNewSessionModal: false
    };
  },

  created() {
    /**
     * TODO: separate NewTrainingSession from data to ensure real persistance
     */

    this.showNewSessionModal = true;
    // TODO: for now commented, meaning every vie change resets session
    // var storedTrainingSession = this.getTrainingSession;
    // if (storedTrainingSession) {
    //   this.trainingSession = storedTrainingSession;
    //   this.showCard = true;
    // } else {
    //   this.showNewSessionModal = true;
    // }
  },
  beforeDestroy() {
    // TODO: here store session to retrieve (not after refresh)
  },
  methods: {
    ...mapMutations(["setTrainingSession"]),
    ...mapActions(["saveRepeat"]),
    showNewSessionModalAction() {
      this.showNewSessionModal = true;
    },
    createNewSession(newSessionWordsIds) {
      const sessionWords = newSessionWordsIds.reduce((dict, el) => {
        return {
          ...dict,
          [el]: this.allWords[el]
        };
      }, {});
      this.trainingSession = new NewTrainingSession(sessionWords);
      this.setTrainingSession(this.trainingSession);
      this.showNewSessionModal = false;
      this.showStats = false;
      this.showCard = true;
      this.currentCard = this.trainingSession.currentCard;
    },

    showTranslation() {
      this.tip = this.trainingSession.currentCard.secondary;
      this.showRatingButtons = true;
    },
    nextWord(wasItGood) {
      // Update UI
      this.showRatingButtons = false;
      this.tip = "_";
      
      // pushes `bad` words to queue again
      this.trainingSession.evaluateCurrentCard(
        AnswerTypeEnum[wasItGood]
      );
      const nextRepeatDate = this.getNextRepeatDateForWord(this.currentCard.id)

      if (!nextRepeatDate || moment().isAfter(moment(nextRepeatDate, "DD-MM-YYYY"))) {
        // TODO: test
        const nextRepeatDate = calculateNextRepeatDate(
          this.currentCard,
          wasItGood
        );
        // execute mutations
        this.saveRepeat({
          cardId: this.currentCard.id,
          repeatDate: nextRepeatDate,
          evaluation: AnswerTypeEnum[wasItGood]
        });
      } 

      // move to next card
      this.currentCard = this.trainingSession.nextCard();
      if (!this.currentCard) {
        this.finishSession();
      }
    },

    finishSession() {
      this.lastSessionStats = this.trainingSession.sessionSummary();
      this.showStats = true;
      this.showCard = false;
    }
  },

  computed: {
    ...mapGetters(["allWords", "getTrainingSession", "getNextRepeatDateForWord"]),
    sessionProgress() {
      if (this.trainingSession !== undefined) {
        const unit = Math.round((1 / this.trainingSession.wordCount) * 100);
        return (
          Math.round(
            (this.trainingSession.cardsSeen / this.trainingSession.wordCount) *
              100
          ) - unit
        );
      } else {
        return 1;
      }
    }
  }
};
</script>
