const _AnswerTypeEnum = {
  good: 1,
  soso: 2,
  bad: 3,
}

const AnswerTypeEnum = Object.freeze(_AnswerTypeEnum)


class NewTrainingSession {
  cardDict = undefined        // dict of words
  cardQueue = undefined    // list of IDs
  currentCard = undefined
  evaluations = []
  repeats = []
  cardsSeen = 0
  wordCount = 0

  constructor(cards) {
    if (!cards) {
      throw "This should not be empty"
    }
    this.cardDict = cards
    this.cardQueue = Object.keys(cards)
    this.currentCard = this.nextCard()
    this.wordCount = this.cardQueue.length
  }

  sessionRepeatCount(wordId) {
    return this.repeats.filter(e => e.wordId == wordId).length
  }

  evaluate(wordId, evaluation) {
    /**
     * Creates history
     * pushes `bad` words back to queue up to 2 times
     * TODO: test
     */

    // session history with evaluation
    this.repeats.push({
      wordId,
      evaluation
    })
    if (evaluation == AnswerTypeEnum.bad && this.sessionRepeatCount(wordId) < 2) {
      this.cardQueue.push(wordId)
    }
  }

  evaluateCurrentCard(evaluation) {
    this.evaluate(this.currentCard.id, evaluation)
  }

  nextCard() {
    if (this.cardQueue.length === 0) {
      this.currentCard = undefined
      return undefined
    } else {
      this.cardsSeen++
      this.currentCard = this.cardDict[this.cardQueue.shift()]
      return this.currentCard
    }
  }

  sessionSummary() {
    return {
      cardsSeen: this.cardsSeen,
      cardsCount: Object.keys(this.cardDict).length,
      sessionRepeats: this.sessionRepeatsCount,
      evaluations: this.evaluations
    }
  }

  get sessionRepeatsCount() {
    return this.repeats.filter(rep => rep.evaluation == AnswerTypeEnum.bad).length
  }

  get sessionState() {
    return [this.cardsSeen, Object.keys(this.cardDict).length, this.sessionRepeatsCount]
  }

}

export {
  NewTrainingSession,
  AnswerTypeEnum
}