<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="tableItems"
      :search="search"
      item-key="id"
      :custom-filter="searchWords"
      :footer-props="rowsPerPageItems"
      :items-per-page="rowsPerPageDefault"
      dense
    >
      <template v-slot:top>
        <v-text-field v-model="search" label="search..." class="mx-4"></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      search: "",
      tableItems: [],
      // pagination
      rowsPerPageItems: {
        'items-per-page-options': [20, 50, 100]
      },
      rowsPerPageDefault: 100,
    };
  },
  created() {
    const dict = this.allWords;

    this.tableItems = Object.keys(dict).map(e => {
      return {
        id: dict[e].id,
        name: dict[e].primary,
        primary: dict[e].primary,
        secondary: dict[e].secondary,
        nextRepeat: dict[e].nextRepeat
      };
    });
  },
  methods: {
    searchWords(value, _search, item) {
      const search = _search.toLocaleLowerCase();
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleLowerCase()
          .indexOf(search) !== -1
      );
    }
  },
  computed: {
    ...mapGetters(["allWords", "getMeta"]),
    headers() {
      return [
        {
          text: "primary",
          align: "start",
          value: "primary"
        },
        {
          text: "secondary",
          align: "start",
          value: "secondary"
        },
        {
          text: "nextRepeat",
          align: "start",
          value: "nextRepeat"
        }
      ];
    }
  }
};
</script>