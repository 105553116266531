<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat>
      <!-- <v-avatar :color="$vuetify.breakpoint.smAndDown ? 'grey darken-1' : 'transparent'" size="32"></v-avatar> -->

      <v-tabs centered class="ml-n9" color="grey darken-1">
        <v-tab v-for="link in links" :key="link.id" :to="link.path">{{ link.title }}</v-tab>
      </v-tabs>

      <v-btn text color="grey darken-2" v-on:click="showSetupModal = true">
        <v-icon color="grey">mdi-cog</v-icon>Settings
      </v-btn>
      <span style="font-size: 0.7em">ver 0.9.1</span>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12">
            <v-sheet min-height="70vh" rounded="lg">
              <router-view v-if="showRouterView"></router-view>
            </v-sheet>
          </v-col>
          <SetupModal
            v-if="showSetupModal"
            v-model="showSetupModal"
            @set-up="googleDocumentDataSetUp"
          />
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SetupModal from "./components/SetupModal";

export default {
  components: {
    SetupModal
  },
  data: () => ({
    showSetupModal: false,
    showRouterView: false,

    links: [
      {
        id: 2,
        title: "train",
        path: "/train"
      },
      {
        id: 3,
        title: "browse",
        path: "/browse"
      },
      {
        id: 4,
        title: "about",
        path: "/about"
      }
    ],
    columnNames: undefined
  }),

  async created() {
    // var paramDocId = this.$route.params.docId;

    if (this.$store.state.words.docId) {
      this.showRouterView = true;
    } else {
      this.showSetupModal = true;
    }
  },

  computed: {
    ...mapGetters(["getAvailableLanguages"])
  },

  methods: {
    googleDocumentDataSetUp() {
      this.showRouterView = true;
    }
  }
};
</script>
