<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ma-1" v-bind="attrs" v-on="on" depressed @click="$emit('button-callback')" v-bind:color="btnColor">
        <v-icon left dark v-if="!!iconName">{{iconName}}</v-icon>
        {{ !!buttonText ? buttonText : ""}}
      </v-btn>
    </template>
    <span>{{ infoTipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["buttonText", "infoTipText", "iconName", "btnColor"]
};
</script>