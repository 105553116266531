import Vuex from 'vuex';
import Vue from 'vue';
import words from './modules/words';
import VuexPersistance from "vuex-persist"

// Load Vuex
Vue.use(Vuex);


const persistFilter = function (mutation) {
  if (mutation.type == "setTrainingSession") {
    return false
  } else {
    return true
  }
}


// persistance plugin (localStorage)
const vuexLocal = new VuexPersistance({
  storage: window.localStorage,
  key: "flashCardsStudioStorage",
  filter: persistFilter
})

// Create store
export default new Vuex.Store({
  modules: {
    words
  },
  plugins: [vuexLocal.plugin]
})
