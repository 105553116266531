import { v4 as uuidv4 } from 'uuid';


function normalizeWords(entries, primaryLanguage, secondaryLanguage) {
  let words = {};
  entries.forEach(el => {
    if (!!el[`gsx$${primaryLanguage}`].$t && el[`gsx$${secondaryLanguage}`].$t) {
      const id = uuidv4()
      words[id] = {
        id,
        primary: el[`gsx$${primaryLanguage}`].$t,
        secondary: el[`gsx$${secondaryLanguage}`].$t,
        sessionLog: [],
        nextRepeat: undefined,
      }
    }
  })
  return words
}

function filterRawData(data) {
  // TODO: filter out rows with all empty values
  return data.map(entry => {
    const keysToSave = Object.keys(entry).filter(key => key.includes("gsx$"))
    return keysToSave.reduce((acc, newKey) => {
      acc[newKey] = entry[newKey]
      return acc
    }, {})
  })
}

function getAvailableLanguages(spreadsheet) {
  const firstRow = spreadsheet.entry[0]
  let languages = [];
  for (const key of Object.keys(firstRow)) {
    if (key.includes("gsx$")) {
      languages.push(key.split("gsx$")[1])
    }
  }
  return languages
}

function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

function extractIdFromUrl(url) {
  const id = url.match(/\/d\/(.*?)\/edit/g);
  const sufix = id[0].match(/\/edit(.*)$/g)
  const sanitized = id[0].replace("/d/", "").replace(sufix[0], "")
  return sanitized
}

export {
  shuffle,
  getAvailableLanguages,
  normalizeWords,
  filterRawData,
  extractIdFromUrl
}