import moment from "moment"
import { AnswerTypeEnum } from "../models/trainingSession"

const eFactorTable = {
  [AnswerTypeEnum.good]: 2.5,
  [AnswerTypeEnum.soso]: 1.9,
  [AnswerTypeEnum.bad]: 1.3,
}

function calculateEFactor(repeats, eFactor) {
  return eFactor
}

function calculateNextRepeatInterval(word, evaluation) {
  const repeats = word.sessionLog.length +1
  const eF = calculateEFactor(repeats, eFactorTable[evaluation])
  return Algo1(repeats, eF)
}

function calculateNextRepeatDate(word, evaluation) {
  const today = moment()
  const interval = calculateNextRepeatInterval(word, evaluation)
  return today.add(interval, "days").format("DD-MM-YYYY")

}

function Algo1(repeats, eFactor) {

  if (!repeats) { return undefined }
  if (repeats < 1) { return undefined }
  if (repeats == 1) { return 1 }
  if (repeats == 2) { return 6 }
  return Algo1(--repeats, eFactor) * eFactor
}

export {
  Algo1,
  calculateNextRepeatDate
}