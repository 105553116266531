import axios from 'axios';
import { getAvailableLanguages, normalizeWords, filterRawData } from '../../utils/utils.js'
import moment from "moment";
import Vue from 'vue'

const state = {
  words: {},
  availableLanguages: [],
  rawEntries: [],
  trainingSession: undefined,
  docId: undefined,
  setupComplete: false,
  meta: {
    docId: "",
    primaryLanguage: "",
    secondaryLanguage: ""
  }
};

const getters = {
  // TODO: change name all name to *Getter() 
  allWords: (state) => state.words,
  getAvailableLanguages: (state) => state.availableLanguages,
  getRawEntries: (state) => state.rawEntries,
  getMeta: (state) => state.meta,
  getTrainingSession: (state) => state.trainingSession,
  getDocId: (state) => state.docId,
  setupCompleteGetter: (state) => state.setupComplete,

  getRepeatsWithOverdues: (state) => {
    return Object.keys(state.words).filter(wordId => moment().isSameOrAfter(moment(state.words[wordId].nextRepeat, "DD-MM-YYYY"), "day")).sort(() => .5 - Math.random())
  },
  getNewWords: (state) => {
    return Object.keys(state.words).filter(wordId => state.words[wordId].sessionLog.length == 0).sort(() => .5 - Math.random())
  },
  getNRandomWords: (state) => {
    return Object.keys(state.words).sort(() => .5 - Math.random())
  },
  getAllRepeats: (state) => {
    return Object.keys(state.words).filter(wordId => {
      return state.words[wordId].nextRepeat != undefined
    }).map(wordId => state.words[wordId])
  },
  getThreeWordsByColumnName: (state) => (columnName) => {
    const asdf = state.rawEntries.slice(0, 3).map(rawEntry => {
      const fieldName = `gsx$${columnName}`
      return rawEntry[fieldName].$t
    }).join(", ")

    return asdf
  },
  getNextRepeatDateForWord: (state) => (wordId) => {
    return state.words[wordId].nextRepeat
  }

};


const GoogleAPI = {
  async fetchSheetData(docId) {
    const response = await axios.get(`https://spreadsheets.google.com/feeds/list/${docId}/1/public/values?alt=json`)
    const columnNames = getAvailableLanguages(response.data.feed)
    const rawEntries = filterRawData(response.data.feed.entry)
    return {
      columnNames,
      rawEntries
    }
  }
}

const actions = {
  saveRepeat({ commit }, payload) {
    const cardId = payload.cardId
    const repeatDate = payload.repeatDate
    const today = moment().format("DD-MM-YYYY")
    commit("pushRepeat", { cardId, repeatDate })
    commit("updateSessionCount", {
      cardId,
      date: today,
      evaluation: payload.evaluation
    })
  },
  async fetchDoc({ commit }, docId) {
    commit("setDocId", docId)
    const googleResponse = await GoogleAPI.fetchSheetData(docId)
    commit("saveAvailableLanguages", googleResponse.columnNames)
    commit("setRawEntries", googleResponse.rawEntries)
  },
  async refreshWordList({ commit, getters }) {
    const googleResponse = await GoogleAPI.fetchSheetData(getters.getDocId)
    commit("setRawEntries", googleResponse.rawEntries)
    const meta = getters.getMeta
    const newWords = normalizeWords(
      googleResponse.rawEntries, meta.primaryLanguage, meta.secondaryLanguage)
    const oldWords = getters.allWords
    Object.keys(newWords).forEach(wordId => {
      const newWord = newWords[wordId]
      const newWordInOldWords = Object.keys(oldWords).filter(oldWordId => {
        if (oldWords[oldWordId].primary == newWord.primary) {
          return true
        } else {
          return false
        }
      })
      if (newWordInOldWords.length == 0) {
        console.log(`Adding ${newWord.primary} to list`)
        commit("addWord", newWord)
      }
    })
  },
  setUpWords({ commit, getters }, [primaryLanguage, secondaryLanguage]) {
    commit("setLanguages", {
      primaryLanguage,
      secondaryLanguage
    })
    const rawEntries = getters.getRawEntries
    const normalizedWords = normalizeWords(rawEntries, primaryLanguage, secondaryLanguage)
    commit("setWords", normalizedWords)
  },
};

const mutations = {
  setSetupComplete: (state, isComplete) => state.setupComplete = isComplete,
  setDocId: (state, docId) => {
    state.docId = docId
  },
  setLanguages: (state, languages) => {
    Vue.set(state, "meta", { primaryLanguage: languages.primaryLanguage, secondaryLanguage: languages.secondaryLanguage })
  },
  // TODO: save -> set
  // TODO: change name to column names
  saveAvailableLanguages: (state, languages) => {
    state.availableLanguages = languages
  },
  addWord: (state, word) => {
    Vue.set(state.words, word.id, word)
  },
  setWords: (state, words) => {
    state.words = words
  },
  setRawEntries: (state, entries) => {
    state.rawEntries = entries
  },
  pushRepeat: (state, payload) => {
    // TODO: DON'T update if next repeat is in the future
    Vue.set(state.words[payload.cardId], "nextRepeat", payload.repeatDate)
  },
  setTrainingSession: (state, trainingSession) => {
    state.trainingSession = trainingSession
  },
  updateSessionCount: (state, payload) => {
    const sessionLog = state.words[payload.cardId].sessionLog
    const todaysLog = sessionLog.filter(log => log.date == payload.date)
    if (!todaysLog.length) {
      sessionLog.push({
        "date": payload.date,
        "evaluation": payload.evaluation
      })
      Vue.set(state.words[payload.cardId], "sessionLog", sessionLog)
    }
  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
