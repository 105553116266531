<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <v-select :items="ids" @change="setCardId"></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-menu
          v-model="datePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="repeatDate"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="repeatDate" @input="datePicker = false"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-btn @click="addRepeat">Add repeat pls</v-btn>
        <v-btn @click="addSessionLog">Update session log</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <h3>getRepeatsWithOverdues</h3>
        <pre> {{ getRepeatsWithOverdues | prettyJson }}</pre>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12">
        <h3>getAllRepeats</h3>
        <pre> {{ getAllRepeats | prettyJson }}</pre>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <h3>allWords</h3>
        <pre>{{allWords | prettyJson}}</pre>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      ids: [],
      repeatDate: undefined,
      datePicker: false,
      currentCID: undefined
    };
  },
  created() {
    const dict = this.allWords;
    this.ids = Object.keys(dict);
  },
  methods: {
    ...mapMutations(["pushRepeat", "updateSessionCount"]),

    setCardId(cid) {
      this.currentCID = cid;
    },
    addSessionLog() {
      console.log(
        `updating session log for ${this.currentCID} @${moment(
          this.repeatDate,
          "YYYY-MM-DD"
        ).format("DD-MM-YYYY")}`
      );
      this.updateSessionCount({
        cardId: this.currentCID,
        date: moment(this.repeatDate, "YYYY-MM-DD").format("DD-MM-YYYY")
      });
    },
    addRepeat() {
      console.log(
        `adding ${this.currentCID} @${moment(
          this.repeatDate,
          "YYYY-MM-DD"
        ).format("DD-MM-YYYY")}`
      );
      this.pushRepeat({
        cardId: this.currentCID,
        date: moment(this.repeatDate, "YYYY-MM-DD").format("DD-MM-YYYY")
      });
    }
  },
  computed: {
    ...mapGetters([
      "allWords",
      "getMeta",
      "allWords",
      "getRepeatsWithOverdues",
      "getAllRepeats"
    ])
  },
  filters: {
    prettyJson: function(value) {
      return JSON.stringify(value, null, 2);
    }
  }
};
</script>