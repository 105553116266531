<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title class="headline grey lighten-2">Create New Session</v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-switch
                  v-model="repeatsModel"
                  :label="`repeats`"
                  :disabled="repeatsDisabled"
                  @change="recalculateFilteredWords"
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-slider
                  v-model="repeatsNumber"
                  :max="repeatsMax"
                  min="1"
                  thumb-label="always"
                  :disabled="!repeatsModel"
                  @change="recalculateFilteredWords"
                ></v-slider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-switch
                  v-model="newWordsModel"
                  :label="`new words`"
                  @change="recalculateFilteredWords"
                  :disabled="newWordsDisabled"
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-slider
                  v-model="newWordsNumber"
                  :max="newWordsMax"
                  min="1"
                  @change="recalculateFilteredWords"
                  thumb-label="always"
                  :disabled="newWordsDisabled"
                ></v-slider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-switch
                  v-model="randomWordsModel"
                  :label="`random words`"
                  @change="recalculateFilteredWords"
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-slider
                  v-model="randomWordsNumber"
                  :max="randomWordsMax"
                  min="1"
                  @change="recalculateFilteredWords"
                  thumb-label="always"
                  :disabled="!randomWordsModel"
                ></v-slider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <p
                  class="sessionSummary text-center"
                >Your session will have {{ wordsLength }} words.</p>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col cols="12" md="12" class="text-right">
              <v-btn
                :disabled="showSessionStart"
                @click="buildSessionWords"
                x-large
                outlined
                color="success"
              >Start session!</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { shuffle } from "../utils/utils";

export default {
  props: {
    value: Boolean
  },
  data: () => ({
    // TODO: is *Disabled needed, can *Model be used?
    // models
    repeatsModel: true,
    newWordsModel: true,
    randomWordsModel: false,
    // default number
    repeatsNumber: 20,
    newWordsNumber: 20,
    randomWordsNumber: 20,
    // max numbers
    repeatsMax: 50,
    newWordsMax: 50,
    randomWordsMax: 999,
    // disabled
    repeatsDisabled: false,
    newWordsDisabled: false,

    // other
    wordsLength: 0,
    // word sets
    repeatsWithOverdues: [],
    newWords: [],
    randomWords: [],
  }),
  created() {
    // TODO: if no repeats and new words, random words should be active
    if (this.getRepeatsWithOverdues.length == 0) {
      this.repeatsModel = false;
      this.repeatsDisabled = true;
    } else {
      this.repeatsMax = this.getRepeatsWithOverdues.length;
    }
    if (this.getNewWords.length == 0) {
      this.newWordsModel = false;
      this.newWordsDisabled = true;
    } else {
      this.newWordsMax = this.getNewWords.length;
    }
    this.wordsLength = this.filteredWords().length
    this.randomWordsMax = Object.keys(this.allWords).length;

    this.randomWords = this.getNRandomWords
  },
  methods: {
    buildSessionWords() {
      this.$emit("newSessionWordIds", this.filteredWords());
    },

    filteredWords: function() {
      const wordIds = [];
      if (this.repeatsModel) {
        wordIds.push(
          ...this.getRepeatsWithOverdues.slice(0, this.repeatsNumber)
        );
      }

      if (this.newWordsModel) {
        wordIds.push(...this.getNewWords.slice(0, this.newWordsNumber));
      }
      if (this.randomWordsModel) {

        var addedRandomWords = 0
        var randomWordsIndex = 0
        while (addedRandomWords < this.randomWordsNumber) {
          var current = this.randomWords[randomWordsIndex]
          if (!wordIds.includes(current)) {
            wordIds.push(current)
            addedRandomWords++
            console.log("adding ", current)
          } else {
            console.log("NOTNOTNOT adding ", current)
          }
          randomWordsIndex++
          if (randomWordsIndex == this.randomWords.length) {
            break
          }
        }
      }
      // TODO: this makes returned array's length indeterminism
      return [...new Set(wordIds)];
    },
    recalculateFilteredWords() {
      this.wordsLength = this.filteredWords().length;
    }
  },
  computed: {
    ...mapGetters([
      "getRepeatsWithOverdues",
      "getNewWords",
      "getNRandomWords",
      "allWords"
    ]),
    showSessionStart: function() {
      return this.wordsLength < 1;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style scoped>
.sessionSummary {
  font-size: 1.5em;
}
</style>