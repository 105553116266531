import Vue from 'vue'
import VueRouter from 'vue-router'
// import FlashCardTest from '../views/FlashCardsTest.vue'
import DebugView from "../views/DebugView"
import Train from '../views/Train'
import BrowseView from '../views/BrowseView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/train'
    // name: 'Flash Card Test',
    // component: FlashCardTest
  },
  {
    path: '/train',
    name: 'Train Cards',
    component: Train
  },
  // {
  //   path: '/train/:docId',
  //   name: 'Train Cards with doc',
  //   component: Train
  // },
  {
    path: '/browse',
    name: 'Browse words',
    component: BrowseView
  },
  // {
  //   path: '/browse/:docId',
  //   name: 'Browse words with docId',
  //   component: BrowseView
  // },
  {
    path: "/debug",
    name: "debug-view",
    component: DebugView
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// TODO: replace with state and local storage, then it's not necessary to pass docId in url
// router.beforeEach((to, from, next) => {
//   next({params: to.params})
// })

export default router
